// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-section {
  background: #f8ffe3;
}
.section-heading {
  text-align: center;
}
.heading-tag {
  padding: 5px 30px;
}
.heading-tag span {
  font-size: 2rem;
  font-weight: 600;
  line-height: 24px;
}
.heading-description p {
  width: 50%;
  margin: 0 auto;
}
.about-section .heading-tag {
  border: 1px solid #225d53;
  background: #e9feab;
}
.about-section .heading-tag span {
  color: #225d53;
}
.about-section h2,
.about-section p {
  color: #225d53;
}
.about-cards-wrapper {
  margin-top: 6rem;
  row-gap: 3rem;
}
.about-card-inner {
  background: #e9feab;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  height: 100%;
  transition: all 0.5s ease;
  position: relative;
}
.card-image img {
  width: 100%;
}
.about-section h3 {
  color: #182a27;
  margin-bottom: 0.5rem;
}

.about-card-inner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #225d53;
  box-shadow: none;
  transition: all 0.3s ease;
  pointer-events: none;
}
.about-card-inner:hover::before {
  border: 1px dashed;
  box-shadow: 0px 8px 20px 0px #00000040;
}
`, "",{"version":3,"sources":["webpack://./src/components/AboutSection/About.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,UAAU;EACV,cAAc;AAChB;AACA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;AACA;EACE,cAAc;AAChB;AACA;;EAEE,cAAc;AAChB;AACA;EACE,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,mBAAmB;EACnB,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;AACpB;AACA;EACE,WAAW;AACb;AACA;EACE,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,yBAAyB;EACzB,gBAAgB;EAChB,yBAAyB;EACzB,oBAAoB;AACtB;AACA;EACE,kBAAkB;EAClB,sCAAsC;AACxC","sourcesContent":[".about-section {\n  background: #f8ffe3;\n}\n.section-heading {\n  text-align: center;\n}\n.heading-tag {\n  padding: 5px 30px;\n}\n.heading-tag span {\n  font-size: 2rem;\n  font-weight: 600;\n  line-height: 24px;\n}\n.heading-description p {\n  width: 50%;\n  margin: 0 auto;\n}\n.about-section .heading-tag {\n  border: 1px solid #225d53;\n  background: #e9feab;\n}\n.about-section .heading-tag span {\n  color: #225d53;\n}\n.about-section h2,\n.about-section p {\n  color: #225d53;\n}\n.about-cards-wrapper {\n  margin-top: 6rem;\n  row-gap: 3rem;\n}\n.about-card-inner {\n  background: #e9feab;\n  padding: 2rem;\n  display: flex;\n  flex-direction: column;\n  gap: 3rem;\n  height: 100%;\n  transition: all 0.5s ease;\n  position: relative;\n}\n.card-image img {\n  width: 100%;\n}\n.about-section h3 {\n  color: #182a27;\n  margin-bottom: 0.5rem;\n}\n\n.about-card-inner::before {\n  content: \"\";\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  border: 1px solid #225d53;\n  box-shadow: none;\n  transition: all 0.3s ease;\n  pointer-events: none;\n}\n.about-card-inner:hover::before {\n  border: 1px dashed;\n  box-shadow: 0px 8px 20px 0px #00000040;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
