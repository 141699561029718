// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.benefits-section {
  background: #e2deff;
}
.benefits-section .heading-tag {
  border: 1px solid #4a03a5;
  background: #d0caff;
}
.benefits-section .heading-tag span {
  color: #4a03a5;
}
.benefits-section h2 {
  color: #4a03a5;
}
.benefits-section .heading-description p {
  color: #4a03a5;
}
.benefits-cards-wrapper {
  margin-top: 8rem;
  row-gap: 3rem;
}
.benefits-card-inner {
  border: 1px solid #fff;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 2rem;
  position: relative;
  height: 100%;
  transition: all 0.3s ease;
  transform: translate(0);
  box-shadow: none;
}
.benefits-card-text h3 {
  color: #111827;
}

.benefits-card-inner:hover {
  border: 1px dashed;
  box-shadow: 0px 8px 20px 0px #00000040;
  cursor: default;
}
@media (max-width: 1024px) {
  .benefits-fourth-card {
    flex-direction: column;
    padding: 3rem;
  }
  .benefits-fourth-card div {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/BenefitsSection/BenefitsSection.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;AACA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,SAAS;EACT,kBAAkB;EAClB,YAAY;EACZ,yBAAyB;EACzB,uBAAuB;EACvB,gBAAgB;AAClB;AACA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,sCAAsC;EACtC,eAAe;AACjB;AACA;EACE;IACE,sBAAsB;IACtB,aAAa;EACf;EACA;IACE,WAAW;EACb;AACF","sourcesContent":[".benefits-section {\r\n  background: #e2deff;\r\n}\r\n.benefits-section .heading-tag {\r\n  border: 1px solid #4a03a5;\r\n  background: #d0caff;\r\n}\r\n.benefits-section .heading-tag span {\r\n  color: #4a03a5;\r\n}\r\n.benefits-section h2 {\r\n  color: #4a03a5;\r\n}\r\n.benefits-section .heading-description p {\r\n  color: #4a03a5;\r\n}\r\n.benefits-cards-wrapper {\r\n  margin-top: 8rem;\r\n  row-gap: 3rem;\r\n}\r\n.benefits-card-inner {\r\n  border: 1px solid #fff;\r\n  background: #fff;\r\n  display: flex;\r\n  flex-direction: column;\r\n  padding: 2rem;\r\n  gap: 2rem;\r\n  position: relative;\r\n  height: 100%;\r\n  transition: all 0.3s ease;\r\n  transform: translate(0);\r\n  box-shadow: none;\r\n}\r\n.benefits-card-text h3 {\r\n  color: #111827;\r\n}\r\n\r\n.benefits-card-inner:hover {\r\n  border: 1px dashed;\r\n  box-shadow: 0px 8px 20px 0px #00000040;\r\n  cursor: default;\r\n}\r\n@media (max-width: 1024px) {\r\n  .benefits-fourth-card {\r\n    flex-direction: column;\r\n    padding: 3rem;\r\n  }\r\n  .benefits-fourth-card div {\r\n    width: 100%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
