// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trusted-partners {
  padding: 8rem 0 !important;
}
.trusted-by-partners-wrapper h2 {
  font-size: 2.8rem;
  text-align: center;
}
.partner-logo-images {
  flex-wrap: wrap;
  column-gap: 12rem;
  justify-content: center;
  margin-top: 3rem;
  align-items: center;
  row-gap: 4rem;
  transition: 0.8s;
  transform: scale(0);
}
.partner-logo-images img {
  width: 90px;
  opacity: 0.7;
}
.partner-logo-images img:hover {
  opacity: 1;
  transition: all 0.3s;
}

.section-animation .partner-logo-images {
  transform: scale(1);
}
@media (max-width: 768px) {
  .trusted-by-partners-wrapper h2 {
    text-align: left;
  }
  .partner-logo-images {
    display: flex;
    gap: 40px;
    margin-top: 4rem;
  }
  .partner-logo-images img {
    width: 14rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/PartnerSection/partner.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;AAC5B;AACA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;EACnB,aAAa;EACb,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,YAAY;AACd;AACA;EACE,UAAU;EACV,oBAAoB;AACtB;;AAEA;EACE,mBAAmB;AACrB;AACA;EACE;IACE,gBAAgB;EAClB;EACA;IACE,aAAa;IACb,SAAS;IACT,gBAAgB;EAClB;EACA;IACE,YAAY;EACd;AACF","sourcesContent":[".trusted-partners {\n  padding: 8rem 0 !important;\n}\n.trusted-by-partners-wrapper h2 {\n  font-size: 2.8rem;\n  text-align: center;\n}\n.partner-logo-images {\n  flex-wrap: wrap;\n  column-gap: 12rem;\n  justify-content: center;\n  margin-top: 3rem;\n  align-items: center;\n  row-gap: 4rem;\n  transition: 0.8s;\n  transform: scale(0);\n}\n.partner-logo-images img {\n  width: 90px;\n  opacity: 0.7;\n}\n.partner-logo-images img:hover {\n  opacity: 1;\n  transition: all 0.3s;\n}\n\n.section-animation .partner-logo-images {\n  transform: scale(1);\n}\n@media (max-width: 768px) {\n  .trusted-by-partners-wrapper h2 {\n    text-align: left;\n  }\n  .partner-logo-images {\n    display: flex;\n    gap: 40px;\n    margin-top: 4rem;\n  }\n  .partner-logo-images img {\n    width: 14rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
