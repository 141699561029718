// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body-wrapper {
    background: #ffffff;
    border: 1px solid #feeaec;
    margin: 2rem 3rem;
    padding: 2rem;
    position: relative;
    display: flex;
    gap: 12rem;
    height: 100%;
  }
  .company-logo {
    width: fit-content;
    position: absolute;
    top: 2rem;
    right: 2rem;
  }
  .error-message {
    color: red;
    font-size: 1.2rem;
    position: absolute;
    bottom: -25px;
  }
  
  @media (max-width: 1440px) {
    .body-wrapper {
      gap: 6rem;
      bottom: -16px;
    }
  }
  @media (max-width: 1150px) {
    .body-wrapper {
      gap: 3rem;
    }
  }
  @media (max-width: 1024px) {
    .error-message {
      bottom: -19px;
      font-size: 1.3rem;
    }
  }
  @media (max-width: 768px) {
    .body-wrapper {
      gap: 0;
      flex-direction: column;
      height: 100% !important;
    }
  }`, "",{"version":3,"sources":["webpack://./src/pages/WatchDemo/WatchDemo.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,yBAAyB;IACzB,iBAAiB;IACjB,aAAa;IACb,kBAAkB;IAClB,aAAa;IACb,UAAU;IACV,YAAY;EACd;EACA;IACE,kBAAkB;IAClB,kBAAkB;IAClB,SAAS;IACT,WAAW;EACb;EACA;IACE,UAAU;IACV,iBAAiB;IACjB,kBAAkB;IAClB,aAAa;EACf;;EAEA;IACE;MACE,SAAS;MACT,aAAa;IACf;EACF;EACA;IACE;MACE,SAAS;IACX;EACF;EACA;IACE;MACE,aAAa;MACb,iBAAiB;IACnB;EACF;EACA;IACE;MACE,MAAM;MACN,sBAAsB;MACtB,uBAAuB;IACzB;EACF","sourcesContent":[".body-wrapper {\n    background: #ffffff;\n    border: 1px solid #feeaec;\n    margin: 2rem 3rem;\n    padding: 2rem;\n    position: relative;\n    display: flex;\n    gap: 12rem;\n    height: 100%;\n  }\n  .company-logo {\n    width: fit-content;\n    position: absolute;\n    top: 2rem;\n    right: 2rem;\n  }\n  .error-message {\n    color: red;\n    font-size: 1.2rem;\n    position: absolute;\n    bottom: -25px;\n  }\n  \n  @media (max-width: 1440px) {\n    .body-wrapper {\n      gap: 6rem;\n      bottom: -16px;\n    }\n  }\n  @media (max-width: 1150px) {\n    .body-wrapper {\n      gap: 3rem;\n    }\n  }\n  @media (max-width: 1024px) {\n    .error-message {\n      bottom: -19px;\n      font-size: 1.3rem;\n    }\n  }\n  @media (max-width: 768px) {\n    .body-wrapper {\n      gap: 0;\n      flex-direction: column;\n      height: 100% !important;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
