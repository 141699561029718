// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 768px) {
  .col-12 {
    padding: 0 !important;
  }
}
@keyframes enter-animation {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@media (prefers-reduced-motion: no-preference) {
  .section-animation {
    animation: enter-animation 0.8s 1;
  }
}

section:not(:nth-of-type(1)) {
  padding: 12rem 0 12rem;
}
@media (max-width: 768px) {
  section:not(:nth-of-type(1)) {
    padding: 6rem 0 6rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE;IACE,qBAAqB;EACvB;AACF;AACA;EACE;IACE,4BAA4B;IAC5B,UAAU;EACZ;EACA;IACE,wBAAwB;IACxB,UAAU;EACZ;AACF;AACA;EACE;IACE,iCAAiC;EACnC;AACF;;AAEA;EACE,sBAAsB;AACxB;AACA;EACE;IACE,oBAAoB;EACtB;AACF","sourcesContent":["@media (max-width: 768px) {\n  .col-12 {\n    padding: 0 !important;\n  }\n}\n@keyframes enter-animation {\n  0% {\n    transform: translateY(100px);\n    opacity: 0;\n  }\n  100% {\n    transform: translateY(0);\n    opacity: 1;\n  }\n}\n@media (prefers-reduced-motion: no-preference) {\n  .section-animation {\n    animation: enter-animation 0.8s 1;\n  }\n}\n\nsection:not(:nth-of-type(1)) {\n  padding: 12rem 0 12rem;\n}\n@media (max-width: 768px) {\n  section:not(:nth-of-type(1)) {\n    padding: 6rem 0 6rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
