// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-content img {
  width: 150px;
}
.sidebar-wrapper {
  background: #feeaec;
  background-repeat: no-repeat;
  padding: 5rem 5rem 0;
  position: relative;
  border: 2px solid #740037;
  height: 100%;
}

.sidebar-content {
  position: relative;
  z-index: 1;
}

.sidebar-content h1 {
  margin-top: 3rem;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2;
  color: #3a3a3a;
}
.sidebar-content p {
  color: #3a3a3a;
}
.sidebar-image {
  margin-top: 3rem;
}
@media (max-width: 1150px) {
  .sidebar-wrapper {
    padding: 5rem 3rem 0;
  }
}

@media (max-width: 768px) {
  .sidebar-wrapper {
    padding: 5rem 3rem 3rem;
  }
  .sidebar-content {
    text-align: center;
  }
  .step-info p {
    display: none;
  }
  .step-info h2 {
    font-size: 1.5rem;
  }
  .sidebar-image {
    display: none;
  }
}
@media (max-width: 576px) {
  .sidebar-content {
    text-align: left;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Book Demo Sidebar/Sidebar.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;AACA;EACE,mBAAmB;EACnB,4BAA4B;EAC5B,oBAAoB;EACpB,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,gBAAgB;AAClB;AACA;EACE;IACE,oBAAoB;EACtB;AACF;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,kBAAkB;EACpB;EACA;IACE,aAAa;EACf;EACA;IACE,iBAAiB;EACnB;EACA;IACE,aAAa;EACf;AACF;AACA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".sidebar-content img {\n  width: 150px;\n}\n.sidebar-wrapper {\n  background: #feeaec;\n  background-repeat: no-repeat;\n  padding: 5rem 5rem 0;\n  position: relative;\n  border: 2px solid #740037;\n  height: 100%;\n}\n\n.sidebar-content {\n  position: relative;\n  z-index: 1;\n}\n\n.sidebar-content h1 {\n  margin-top: 3rem;\n  font-size: 3rem;\n  font-weight: 700;\n  line-height: 1.2;\n  color: #3a3a3a;\n}\n.sidebar-content p {\n  color: #3a3a3a;\n}\n.sidebar-image {\n  margin-top: 3rem;\n}\n@media (max-width: 1150px) {\n  .sidebar-wrapper {\n    padding: 5rem 3rem 0;\n  }\n}\n\n@media (max-width: 768px) {\n  .sidebar-wrapper {\n    padding: 5rem 3rem 3rem;\n  }\n  .sidebar-content {\n    text-align: center;\n  }\n  .step-info p {\n    display: none;\n  }\n  .step-info h2 {\n    font-size: 1.5rem;\n  }\n  .sidebar-image {\n    display: none;\n  }\n}\n@media (max-width: 576px) {\n  .sidebar-content {\n    text-align: left;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
